import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { NetworkIndicatorService } from 'src/app/shared/services/network-indicator.service';

import { Observable } from 'rxjs';
import { finalize } from 'rxjs/operators';

@Injectable()
export class NetworkIndicatorInterceptor implements HttpInterceptor {
  constructor(
    public networkIndicatorService: NetworkIndicatorService,
  ) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    this.networkIndicatorService.show();

    return next.handle(request).pipe(
      finalize(() => this.networkIndicatorService.hide()),
    );
  }
}
