import { API } from '@sentry/core';
import { PromiseBuffer, SentryError } from '@sentry/utils';
/** Base Transport class implementation */
var BaseTransport = /** @class */function () {
  function BaseTransport(options) {
    this.options = options;
    /** A simple buffer holding all requests. */
    this._buffer = new PromiseBuffer(30);
    this._api = new API(this.options.dsn);
    // tslint:disable-next-line:deprecation
    this.url = this._api.getStoreEndpointWithUrlEncodedAuth();
  }
  /**
   * @inheritDoc
   */
  BaseTransport.prototype.sendEvent = function (_) {
    throw new SentryError('Transport Class has to implement `sendEvent` method');
  };
  /**
   * @inheritDoc
   */
  BaseTransport.prototype.close = function (timeout) {
    return this._buffer.drain(timeout);
  };
  return BaseTransport;
}();
export { BaseTransport };
