import * as tslib_1 from "tslib";
import { getCurrentHub } from '@sentry/core';
import { Severity } from '@sentry/types';
import { addInstrumentationHandler, getEventDescription, getGlobalObject, htmlTreeAsString, parseUrl, safeJoin } from '@sentry/utils';
/**
 * Default Breadcrumbs instrumentations
 * TODO: Deprecated - with v6, this will be renamed to `Instrument`
 */
var Breadcrumbs = /** @class */function () {
  /**
   * @inheritDoc
   */
  function Breadcrumbs(options) {
    /**
     * @inheritDoc
     */
    this.name = Breadcrumbs.id;
    this._options = tslib_1.__assign({
      console: true,
      dom: true,
      fetch: true,
      history: true,
      sentry: true,
      xhr: true
    }, options);
  }
  /**
   * Create a breadcrumb of `sentry` from the events themselves
   */
  Breadcrumbs.prototype.addSentryBreadcrumb = function (event) {
    if (!this._options.sentry) {
      return;
    }
    getCurrentHub().addBreadcrumb({
      category: "sentry." + (event.type === 'transaction' ? 'transaction' : 'event'),
      event_id: event.event_id,
      level: event.level,
      message: getEventDescription(event)
    }, {
      event: event
    });
  };
  /**
   * Creates breadcrumbs from console API calls
   */
  Breadcrumbs.prototype._consoleBreadcrumb = function (handlerData) {
    var breadcrumb = {
      category: 'console',
      data: {
        arguments: handlerData.args,
        logger: 'console'
      },
      level: Severity.fromString(handlerData.level),
      message: safeJoin(handlerData.args, ' ')
    };
    if (handlerData.level === 'assert') {
      if (handlerData.args[0] === false) {
        breadcrumb.message = "Assertion failed: " + (safeJoin(handlerData.args.slice(1), ' ') || 'console.assert');
        breadcrumb.data.arguments = handlerData.args.slice(1);
      } else {
        // Don't capture a breadcrumb for passed assertions
        return;
      }
    }
    getCurrentHub().addBreadcrumb(breadcrumb, {
      input: handlerData.args,
      level: handlerData.level
    });
  };
  /**
   * Creates breadcrumbs from DOM API calls
   */
  Breadcrumbs.prototype._domBreadcrumb = function (handlerData) {
    var target;
    // Accessing event.target can throw (see getsentry/raven-js#838, #768)
    try {
      target = handlerData.event.target ? htmlTreeAsString(handlerData.event.target) : htmlTreeAsString(handlerData.event);
    } catch (e) {
      target = '<unknown>';
    }
    if (target.length === 0) {
      return;
    }
    getCurrentHub().addBreadcrumb({
      category: "ui." + handlerData.name,
      message: target
    }, {
      event: handlerData.event,
      name: handlerData.name
    });
  };
  /**
   * Creates breadcrumbs from XHR API calls
   */
  Breadcrumbs.prototype._xhrBreadcrumb = function (handlerData) {
    if (handlerData.endTimestamp) {
      // We only capture complete, non-sentry requests
      if (handlerData.xhr.__sentry_own_request__) {
        return;
      }
      getCurrentHub().addBreadcrumb({
        category: 'xhr',
        data: handlerData.xhr.__sentry_xhr__,
        type: 'http'
      }, {
        xhr: handlerData.xhr
      });
      return;
    }
  };
  /**
   * Creates breadcrumbs from fetch API calls
   */
  Breadcrumbs.prototype._fetchBreadcrumb = function (handlerData) {
    // We only capture complete fetch requests
    if (!handlerData.endTimestamp) {
      return;
    }
    if (handlerData.fetchData.url.match(/sentry_key/) && handlerData.fetchData.method === 'POST') {
      // We will not create breadcrumbs for fetch requests that contain `sentry_key` (internal sentry requests)
      return;
    }
    if (handlerData.error) {
      getCurrentHub().addBreadcrumb({
        category: 'fetch',
        data: handlerData.fetchData,
        level: Severity.Error,
        type: 'http'
      }, {
        data: handlerData.error,
        input: handlerData.args
      });
    } else {
      getCurrentHub().addBreadcrumb({
        category: 'fetch',
        data: tslib_1.__assign({}, handlerData.fetchData, {
          status_code: handlerData.response.status
        }),
        type: 'http'
      }, {
        input: handlerData.args,
        response: handlerData.response
      });
    }
  };
  /**
   * Creates breadcrumbs from history API calls
   */
  Breadcrumbs.prototype._historyBreadcrumb = function (handlerData) {
    var global = getGlobalObject();
    var from = handlerData.from;
    var to = handlerData.to;
    var parsedLoc = parseUrl(global.location.href);
    var parsedFrom = parseUrl(from);
    var parsedTo = parseUrl(to);
    // Initial pushState doesn't provide `from` information
    if (!parsedFrom.path) {
      parsedFrom = parsedLoc;
    }
    // Use only the path component of the URL if the URL matches the current
    // document (almost all the time when using pushState)
    if (parsedLoc.protocol === parsedTo.protocol && parsedLoc.host === parsedTo.host) {
      // tslint:disable-next-line:no-parameter-reassignment
      to = parsedTo.relative;
    }
    if (parsedLoc.protocol === parsedFrom.protocol && parsedLoc.host === parsedFrom.host) {
      // tslint:disable-next-line:no-parameter-reassignment
      from = parsedFrom.relative;
    }
    getCurrentHub().addBreadcrumb({
      category: 'navigation',
      data: {
        from: from,
        to: to
      }
    });
  };
  /**
   * Instrument browser built-ins w/ breadcrumb capturing
   *  - Console API
   *  - DOM API (click/typing)
   *  - XMLHttpRequest API
   *  - Fetch API
   *  - History API
   */
  Breadcrumbs.prototype.setupOnce = function () {
    var _this = this;
    if (this._options.console) {
      addInstrumentationHandler({
        callback: function () {
          var args = [];
          for (var _i = 0; _i < arguments.length; _i++) {
            args[_i] = arguments[_i];
          }
          _this._consoleBreadcrumb.apply(_this, tslib_1.__spread(args));
        },
        type: 'console'
      });
    }
    if (this._options.dom) {
      addInstrumentationHandler({
        callback: function () {
          var args = [];
          for (var _i = 0; _i < arguments.length; _i++) {
            args[_i] = arguments[_i];
          }
          _this._domBreadcrumb.apply(_this, tslib_1.__spread(args));
        },
        type: 'dom'
      });
    }
    if (this._options.xhr) {
      addInstrumentationHandler({
        callback: function () {
          var args = [];
          for (var _i = 0; _i < arguments.length; _i++) {
            args[_i] = arguments[_i];
          }
          _this._xhrBreadcrumb.apply(_this, tslib_1.__spread(args));
        },
        type: 'xhr'
      });
    }
    if (this._options.fetch) {
      addInstrumentationHandler({
        callback: function () {
          var args = [];
          for (var _i = 0; _i < arguments.length; _i++) {
            args[_i] = arguments[_i];
          }
          _this._fetchBreadcrumb.apply(_this, tslib_1.__spread(args));
        },
        type: 'fetch'
      });
    }
    if (this._options.history) {
      addInstrumentationHandler({
        callback: function () {
          var args = [];
          for (var _i = 0; _i < arguments.length; _i++) {
            args[_i] = arguments[_i];
          }
          _this._historyBreadcrumb.apply(_this, tslib_1.__spread(args));
        },
        type: 'history'
      });
    }
  };
  /**
   * @inheritDoc
   */
  Breadcrumbs.id = 'Breadcrumbs';
  return Breadcrumbs;
}();
export { Breadcrumbs };
