import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { CookieService } from 'ngx-cookie-service';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { JwtService } from '../services/jwt.service';
import { NotificationService } from '../services/notification.service';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
  constructor(
    private notificationService: NotificationService,
    private cookieService: CookieService,
    private jwtSerivce: JwtService,
  ) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      catchError((error) => {
        if (error.status === 401) {
          this.notificationService.showError('You are unauthorized to access this resource, Please login again');
          this.jwtSerivce.destroyToken();
          this.cookieService.delete('user');
        }
        if (error.status === 403) {
          if (error.error.detail) {
            this.notificationService.showError(error.error.detail);
          } else {
            this.notificationService.showError(error.error.error.message);
          }
        }
        return throwError(error);
      }),
    );
  }
}
