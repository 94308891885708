import * as tslib_1 from "tslib";
import { addGlobalEventProcessor, getCurrentHub } from '@sentry/hub';
import { getEventDescription, isMatchingPattern, logger } from '@sentry/utils';
// "Script error." is hard coded into browsers for errors that it can't read.
// this is the result of a script being pulled in from an external domain and CORS.
var DEFAULT_IGNORE_ERRORS = [/^Script error\.?$/, /^Javascript error: Script error\.? on line 0$/];
/** Inbound filters configurable by the user */
var InboundFilters = /** @class */function () {
  function InboundFilters(_options) {
    if (_options === void 0) {
      _options = {};
    }
    this._options = _options;
    /**
     * @inheritDoc
     */
    this.name = InboundFilters.id;
  }
  /**
   * @inheritDoc
   */
  InboundFilters.prototype.setupOnce = function () {
    addGlobalEventProcessor(function (event) {
      var hub = getCurrentHub();
      if (!hub) {
        return event;
      }
      var self = hub.getIntegration(InboundFilters);
      if (self) {
        var client = hub.getClient();
        var clientOptions = client ? client.getOptions() : {};
        var options = self._mergeOptions(clientOptions);
        if (self._shouldDropEvent(event, options)) {
          return null;
        }
      }
      return event;
    });
  };
  /** JSDoc */
  InboundFilters.prototype._shouldDropEvent = function (event, options) {
    if (this._isSentryError(event, options)) {
      logger.warn("Event dropped due to being internal Sentry Error.\nEvent: " + getEventDescription(event));
      return true;
    }
    if (this._isIgnoredError(event, options)) {
      logger.warn("Event dropped due to being matched by `ignoreErrors` option.\nEvent: " + getEventDescription(event));
      return true;
    }
    if (this._isDeniedUrl(event, options)) {
      logger.warn("Event dropped due to being matched by `denyUrls` option.\nEvent: " + getEventDescription(event) + ".\nUrl: " + this._getEventFilterUrl(event));
      return true;
    }
    if (!this._isAllowedUrl(event, options)) {
      logger.warn("Event dropped due to not being matched by `allowUrls` option.\nEvent: " + getEventDescription(event) + ".\nUrl: " + this._getEventFilterUrl(event));
      return true;
    }
    return false;
  };
  /** JSDoc */
  InboundFilters.prototype._isSentryError = function (event, options) {
    if (!options.ignoreInternal) {
      return false;
    }
    try {
      return event && event.exception && event.exception.values && event.exception.values[0] && event.exception.values[0].type === 'SentryError' || false;
    } catch (_oO) {
      return false;
    }
  };
  /** JSDoc */
  InboundFilters.prototype._isIgnoredError = function (event, options) {
    if (!options.ignoreErrors || !options.ignoreErrors.length) {
      return false;
    }
    return this._getPossibleEventMessages(event).some(function (message) {
      // Not sure why TypeScript complains here...
      return options.ignoreErrors.some(function (pattern) {
        return isMatchingPattern(message, pattern);
      });
    });
  };
  /** JSDoc */
  InboundFilters.prototype._isDeniedUrl = function (event, options) {
    // TODO: Use Glob instead?
    if (!options.denyUrls || !options.denyUrls.length) {
      return false;
    }
    var url = this._getEventFilterUrl(event);
    return !url ? false : options.denyUrls.some(function (pattern) {
      return isMatchingPattern(url, pattern);
    });
  };
  /** JSDoc */
  InboundFilters.prototype._isAllowedUrl = function (event, options) {
    // TODO: Use Glob instead?
    if (!options.allowUrls || !options.allowUrls.length) {
      return true;
    }
    var url = this._getEventFilterUrl(event);
    return !url ? true : options.allowUrls.some(function (pattern) {
      return isMatchingPattern(url, pattern);
    });
  };
  /** JSDoc */
  InboundFilters.prototype._mergeOptions = function (clientOptions) {
    if (clientOptions === void 0) {
      clientOptions = {};
    }
    // tslint:disable:deprecation
    return {
      allowUrls: tslib_1.__spread(this._options.whitelistUrls || [], this._options.allowUrls || [], clientOptions.whitelistUrls || [], clientOptions.allowUrls || []),
      denyUrls: tslib_1.__spread(this._options.blacklistUrls || [], this._options.denyUrls || [], clientOptions.blacklistUrls || [], clientOptions.denyUrls || []),
      ignoreErrors: tslib_1.__spread(this._options.ignoreErrors || [], clientOptions.ignoreErrors || [], DEFAULT_IGNORE_ERRORS),
      ignoreInternal: typeof this._options.ignoreInternal !== 'undefined' ? this._options.ignoreInternal : true
    };
  };
  /** JSDoc */
  InboundFilters.prototype._getPossibleEventMessages = function (event) {
    if (event.message) {
      return [event.message];
    }
    if (event.exception) {
      try {
        var _a = event.exception.values && event.exception.values[0] || {},
          _b = _a.type,
          type = _b === void 0 ? '' : _b,
          _c = _a.value,
          value = _c === void 0 ? '' : _c;
        return ["" + value, type + ": " + value];
      } catch (oO) {
        logger.error("Cannot extract message for event " + getEventDescription(event));
        return [];
      }
    }
    return [];
  };
  /** JSDoc */
  InboundFilters.prototype._getEventFilterUrl = function (event) {
    try {
      if (event.stacktrace) {
        var frames_1 = event.stacktrace.frames;
        return frames_1 && frames_1[frames_1.length - 1].filename || null;
      }
      if (event.exception) {
        var frames_2 = event.exception.values && event.exception.values[0].stacktrace && event.exception.values[0].stacktrace.frames;
        return frames_2 && frames_2[frames_2.length - 1].filename || null;
      }
      return null;
    } catch (oO) {
      logger.error("Cannot extract url for event " + getEventDescription(event));
      return null;
    }
  };
  /**
   * @inheritDoc
   */
  InboundFilters.id = 'InboundFilters';
  return InboundFilters;
}();
export { InboundFilters };
