import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgSelectModule } from '@ng-select/ng-select';

import { DashboardRoutingModule } from 'src/app/modules/dashboard/dashboard-routing.module';
import { DashboardComponent } from 'src/app/modules/dashboard/dashboard/dashboard.component';
import { SharedModule } from 'src/app/modules/shared/shared.module';

import { NgxPermissionsModule } from 'ngx-permissions';

@NgModule({
  declarations: [DashboardComponent],
  imports: [
    CommonModule,
    FormsModule,
    DashboardRoutingModule,
    SharedModule,
    NgbModule,
    NgSelectModule,
    NgxPermissionsModule,
  ],
})
export class DashboardModule { }
