import { DragDropModule } from '@angular/cdk/drag-drop';
import { CommonModule } from '@angular/common';
import { HTTP_INTERCEPTORS, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { ErrorHandler, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgbActiveModal, NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgSelectModule } from '@ng-select/ng-select';
import * as Sentry from '@sentry/browser';

import { AuthGuard } from './shared/guards/auth.guard';
import { AuthService } from './shared/services/auth.service';
import { ShellComponent } from './shell/shell.component';

import { AppRoutingModule } from 'src/app/app-routing.module';
import { AppComponent } from 'src/app/app.component';
import { AuthModule } from 'src/app/modules/auth/auth.module';
import { DashboardModule } from 'src/app/modules/dashboard/dashboard.module';
import { SettingModule } from 'src/app/modules/setting/setting.module';
import { SharedModule } from 'src/app/modules/shared/shared.module';
import { SentryErrorHandler } from 'src/app/sentry-handler';
import { CacheInterceptor } from 'src/app/shared/helpers/cache.interceptor';
import { ErrorInterceptor } from 'src/app/shared/helpers/error.interceptor';
import { NetworkIndicatorInterceptor } from 'src/app/shared/helpers/network-indicator.interceptor';
import { TokenInterceptor } from 'src/app/shared/helpers/token.interceptor';
import { environment } from 'src/environments/environment';

import { CookieService } from 'ngx-cookie-service';
import { NgxFileDropModule } from 'ngx-file-drop';
import { NgxPermissionsModule } from 'ngx-permissions';
import { ToastrModule } from 'ngx-toastr';

if (environment.dsnSentry) {
  Sentry.init({
    dsn: environment.dsnSentry,
    environment: environment.environment,
    tracesSampleRate: environment.tracesSampleRate,
  });
}

@NgModule({ declarations: [
        AppComponent,
        ShellComponent,
    ],
    bootstrap: [
        AppComponent,
    ], imports: [BrowserModule,
        FormsModule,
        AppRoutingModule,
        AuthModule,
        DashboardModule,
        SharedModule,
        SettingModule,
        CommonModule,
        NgbModule,
        BrowserAnimationsModule,
        ToastrModule.forRoot(),
        NgxFileDropModule,
        NgxPermissionsModule.forRoot(),
        DragDropModule,
        NgSelectModule], providers: [
        NgbActiveModal,
        CookieService,
        AuthService,
        AuthGuard,
        {
            provide: HTTP_INTERCEPTORS,
            useClass: ErrorInterceptor,
            multi: true,
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: TokenInterceptor,
            multi: true,
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: NetworkIndicatorInterceptor,
            multi: true,
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: CacheInterceptor,
            multi: true,
        },
        ...(environment.dsnSentry ? [{
                provide: ErrorHandler,
                useClass: SentryErrorHandler,
            }] : []),
        provideHttpClient(withInterceptorsFromDi()),
    ] })
export class AppModule { }
