import * as tslib_1 from "tslib";
import { eventToSentryRequest } from '@sentry/core';
import { Status } from '@sentry/types';
import { getGlobalObject, logger, parseRetryAfterHeader, supportsReferrerPolicy, SyncPromise } from '@sentry/utils';
import { BaseTransport } from './base';
var global = getGlobalObject();
/** `fetch` based transport */
var FetchTransport = /** @class */function (_super) {
  tslib_1.__extends(FetchTransport, _super);
  function FetchTransport() {
    var _this = _super !== null && _super.apply(this, arguments) || this;
    /** Locks transport after receiving 429 response */
    _this._disabledUntil = new Date(Date.now());
    return _this;
  }
  /**
   * @inheritDoc
   */
  FetchTransport.prototype.sendEvent = function (event) {
    var _this = this;
    if (new Date(Date.now()) < this._disabledUntil) {
      return Promise.reject({
        event: event,
        reason: "Transport locked till " + this._disabledUntil + " due to too many requests.",
        status: 429
      });
    }
    var sentryReq = eventToSentryRequest(event, this._api);
    var options = {
      body: sentryReq.body,
      method: 'POST',
      // Despite all stars in the sky saying that Edge supports old draft syntax, aka 'never', 'always', 'origin' and 'default
      // https://caniuse.com/#feat=referrer-policy
      // It doesn't. And it throw exception instead of ignoring this parameter...
      // REF: https://github.com/getsentry/raven-js/issues/1233
      referrerPolicy: supportsReferrerPolicy() ? 'origin' : ''
    };
    if (this.options.fetchParameters !== undefined) {
      Object.assign(options, this.options.fetchParameters);
    }
    if (this.options.headers !== undefined) {
      options.headers = this.options.headers;
    }
    return this._buffer.add(new SyncPromise(function (resolve, reject) {
      global.fetch(sentryReq.url, options).then(function (response) {
        var status = Status.fromHttpCode(response.status);
        if (status === Status.Success) {
          resolve({
            status: status
          });
          return;
        }
        if (status === Status.RateLimit) {
          var now = Date.now();
          _this._disabledUntil = new Date(now + parseRetryAfterHeader(now, response.headers.get('Retry-After')));
          logger.warn("Too many requests, backing off till: " + _this._disabledUntil);
        }
        reject(response);
      }).catch(reject);
    }));
  };
  return FetchTransport;
}(BaseTransport);
export { FetchTransport };
