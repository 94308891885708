import { Component, OnInit } from '@angular/core';

import { BreadcrumbService } from 'src/app/shared/services/breadcrumb.service';
import { CoreService } from 'src/app/shared/services/core.service';

import { Chart } from 'chart.js';
import { NgxPermissionsService } from 'ngx-permissions';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss'],
})
export class DashboardComponent implements OnInit {
  private dataSets = [];
  label1SelectedOptions = null;
  label2SelectedOptions = null;
  myChart = null;
  labels = [
      'Mon',
      'Tue',
      'Wed',
      'Thu',
      'Fri',
      'Sat',
      'Sun',
  ];
  impressions = {
    label: ' Impressions',
    backgroundColor: '#E74747',
    borderWidth: 2,
    borderColor: '#E74747',
    data: [3000, 3500, 2000, 4000, 3800, 7000, 6000],
    fill: false,
    pointBorderColor: '#FFF',
    pointBackgroundColor: '#E74747',
    pointHoverBorderColor: '#E74747',
    pointHoverBackgroundColor: '#fff',
  };
  impressions2 = {
    label: ' Impressions',
    backgroundColor: '#E74747',
    borderWidth: 2,
    borderColor: '#E74747',
    data: [300022, 350033, 200044, 400055, 380066, 700077, 600088],
    fill: false,
    pointBorderColor: '#FFF',
    pointBackgroundColor: '#E74747',
    pointHoverBorderColor: '#E74747',
    pointHoverBackgroundColor: '#fff',
  };
  payout = {
    label: 'Payout',
    backgroundColor: '#3d89a8',
    borderWidth: 2,
    borderColor: '#3d89a8',
    data: [1000, 1500, 500, 2000, 2500, 4500, 3000],
    fill: false,
    pointBorderColor: '#FFF',
    pointBackgroundColor: '#3d89a8',
    pointHoverBorderColor: '#3d89a8',
    pointHoverBackgroundColor: '#fff',
  };
  payout2 = {
    label: 'Payout',
    backgroundColor: '#3d89a8',
    borderWidth: 2,
    borderColor: '#3d89a8',
    data: [100011, 150022, 50033, 200044, 250055, 450066, 300077],
    fill: false,
    pointBorderColor: '#FFF',
    pointBackgroundColor: '#3d89a8',
    pointHoverBorderColor: '#3d89a8',
    pointHoverBackgroundColor: '#fff',
  };
  public label1Options = [
    { id: 1, name: 'Impressions' },
    { id: 2, name: 'Impressions2' },
    { id: 3, name: 'Impressions3' },
  ];
  public label2Options = [
    { id: 1, name: 'Payout' },
    { id: 2, name: 'Payout2' },
    { id: 3, name: 'Payout3' },
  ];
  private permissions = [];

  public SelectOption = [
    'Last Week',
    'Last Month',
    'Last Year',
  ];
  breadcrumbData = [
    { name: 'Dashboard', link: '/dashboard' },
  ];

  constructor(
    public breadcrumbService: BreadcrumbService,
    private permissionsService: NgxPermissionsService,
    private coreService: CoreService,
  ) {
    this.permissions.push(this.coreService.getCurrentAccountType());
    this.permissionsService.loadPermissions(this.permissions);
  }

  ngOnInit() {
    this.breadcrumbService.updateBreadcrumbsData(this.breadcrumbData);
    this.loadDefaultChart();
  }

  createChart() {
    const ctx = document.getElementById('PerformanceChart');
    this.myChart = new Chart(ctx, {
      type: 'line',
      data: {
        labels: this.labels,
        datasets: this.dataSets,
      },
      options: {
        responsive: true,
        responsiveAnimationDuration: 0,
        maintainAspectRatio: false,
        title: {
          display: false,
          text: '',
        },
        animation: {
          duration: 0,
        },
        hover: {
          animationDuration: 0,
        },
        tooltips: {
          mode: 'index',
          backgroundColor: '#FFF',
          borderColor: '#dfe1e6',
          intersect: false,
          borderWidth: 2,
          titleMarginBottom: 8,
          bodySpacing: 8,
          xPadding: 10,
          yPadding: 10,
          titleFontColor: '#172b4d',
          titleFontFamily: 'Cabin',
          bodyFontColor: '#5e6c84',
          bodyFontFamily: 'Cabin',
          callbacks: {
            title(tooltipItem, data) {
              return data['labels'][tooltipItem[0]['index']] + ' , 2019';
            },
          },
        },
        scales: {
          xAxes: [{
            display: true,
            scaleLabel: {
              display: false,
              labelString: 'Month',
              fontColor: '#5e6c84',
            },
            gridLines: {
              lineWidth: 0,
              color: '#F5F5F5',
              zeroLineWidth: 0,
              zeroLineColor: '#F5F5F5',
            },
            ticks: {
              fontColor: '#3C89AE',
              fontFamily: 'Cabin',
            },
          }],
          yAxes: [{
            display: true,
            scaleLabel: {
              display: false,
              labelString: 'Value',
              fontColor: '#5e6c84',
            },
            gridLines: {
              lineWidth: 1,
              color: '#F5F5F5',
              zeroLineWidth: 1,
              zeroLineColor: '#F5F5F5',
            },
            ticks: {
              fontColor: '#3C89AE',
              fontFamily: 'Cabin',
              min: 0,
              padding: 50,
            },
          }],
        },
        elements: {
          line: {
            tension: 0.000001,
          },
          point: {
            radius: 0,
            borderWidth: 0,
          },
        },
        legend: {
          display: false,
          labels: {
            fontSize: 15,
            padding: 20,
            fontColor: '#979ea9',
            usePointStyle: true,
          },
        },
      },
    });
  }

  loadDefaultChart() {
    this.label1SelectedOptions = this.label1Options[0].id;
    this.label2SelectedOptions = this.label2Options[0].id;
    this.dataSets[0] = this.impressions;
    this.dataSets[1] = this.payout;
  }

  changeGraphData1(data) {
    if (data.name === 'Impressions') {
      this.dataSets[0] = this.impressions;
      this.myChart.update();
    } else if (data.name === 'Impressions2') {
      this.dataSets[0] = this.impressions2;
      this.myChart.update();
    }
  }

  changeGraphData2(data) {
    if (data.name === 'Payout') {
      this.dataSets[1] = this.payout;
      this.myChart.update();
    } else if (data.name === 'Payout2') {
      this.dataSets[1] = this.payout2;
      this.myChart.update();
    }
  }
}
