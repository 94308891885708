import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router } from '@angular/router';

import { CoreService } from 'src/app/shared/services/core.service';

@Injectable({
  providedIn: 'root',
})
export class ActiveUserGuard  {

  constructor(private router: Router,
    private coreService: CoreService) { }

  async canActivate(_next: ActivatedRouteSnapshot) {
    const status = await this.coreService.getCurrentUserStatus();
    const active = await this.coreService.isCurrentUserActive();

    if (active) {
      return true;
    }

    this.router.navigate(['/message'], {
      queryParams: {
        title: `Your Account Status is ${status}`,
      },
    });

    return false;
  }

}
