import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { CacheService } from 'src/app/shared/services/cache.service';

import { chain, unset } from 'lodash';
import { Observable } from 'rxjs';
import { filter, first, shareReplay } from 'rxjs/operators';

/*
  Returns the first parent of the url i.e. one directory level backwards.
  For example: https://example.com/lodash will become https://example.com.
*/
const getUrlParent = (url: string) => {
  const normalUrl = !url.endsWith('/') ?
    `${url}/` :
    url;

  return chain(normalUrl)
    .split('/')
    .dropRight(2)
    .join('/')
    .value();
};

@Injectable()
export class CacheInterceptor implements HttpInterceptor {
  public store: Record<string, Observable<HttpEvent<any>>> = {};

  constructor(private cacheService: CacheService) {
    this.cacheService.resetAppCache$.subscribe((flag) => {
      if (flag) {
        this.store = {};
      }
    });
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // Don't cache if the request type is not of type GET and clear the
    // requests that share the same base url so the the data is refreshed
    // the next time.
    if (req.method !== 'GET') {
      for (const url of [req.url, getUrlParent(req.url)]) {
        Object.keys(this.store).forEach((key) => {
          if (key.startsWith(url)) {
            unset(this.store, key);
          }
        });
      }

      return next.handle(req);
    }

    const skip = (
      req.headers.get('APP-CACHE-SKIP') === 'true'
    );

    /*
      CORS Options requests do not always allow custom headers, therefore
      clearing any custom headers would be safer.
    */
    req = req.clone({
      headers: req.headers.delete(
        'APP-CACHE-SKIP',
      ),
    });

    // Check if observable is in cache, otherwise call next.handle
    const cachedObservable = (!skip && this.store[req.urlWithParams]) || (
      this.store[req.urlWithParams] = next.handle(req).pipe(
        filter((res) => res instanceof HttpResponse),
        shareReplay(1),
      )
    );

    // pipe first() to cause the observable to complete after it emits
    // the response. This mimics the behaviour of Observables returned by
    // Angular's httpClient.get() And also makes toPromise work since toPromise
    // will wait until the observable completes.
    return cachedObservable.pipe(
      first(),
    );
  }
}
