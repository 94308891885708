import { Dsn, urlEncode } from '@sentry/utils';
var SENTRY_API_VERSION = '7';
/** Helper class to provide urls to different Sentry endpoints. */
var API = /** @class */function () {
  /** Create a new instance of API */
  function API(dsn) {
    this.dsn = dsn;
    this._dsnObject = new Dsn(dsn);
  }
  /** Returns the Dsn object. */
  API.prototype.getDsn = function () {
    return this._dsnObject;
  };
  /** Returns the prefix to construct Sentry ingestion API endpoints. */
  API.prototype.getBaseApiEndpoint = function () {
    var dsn = this._dsnObject;
    var protocol = dsn.protocol ? dsn.protocol + ":" : '';
    var port = dsn.port ? ":" + dsn.port : '';
    return protocol + "//" + dsn.host + port + (dsn.path ? "/" + dsn.path : '') + "/api/";
  };
  /** Returns the store endpoint URL. */
  API.prototype.getStoreEndpoint = function () {
    return this._getIngestEndpoint('store');
  };
  /** Returns the envelope endpoint URL. */
  API.prototype._getEnvelopeEndpoint = function () {
    return this._getIngestEndpoint('envelope');
  };
  /** Returns the ingest API endpoint for target. */
  API.prototype._getIngestEndpoint = function (target) {
    var base = this.getBaseApiEndpoint();
    var dsn = this._dsnObject;
    return "" + base + dsn.projectId + "/" + target + "/";
  };
  /**
   * Returns the store endpoint URL with auth in the query string.
   *
   * Sending auth as part of the query string and not as custom HTTP headers avoids CORS preflight requests.
   */
  API.prototype.getStoreEndpointWithUrlEncodedAuth = function () {
    return this.getStoreEndpoint() + "?" + this._encodedAuth();
  };
  /**
   * Returns the envelope endpoint URL with auth in the query string.
   *
   * Sending auth as part of the query string and not as custom HTTP headers avoids CORS preflight requests.
   */
  API.prototype.getEnvelopeEndpointWithUrlEncodedAuth = function () {
    return this._getEnvelopeEndpoint() + "?" + this._encodedAuth();
  };
  /** Returns a URL-encoded string with auth config suitable for a query string. */
  API.prototype._encodedAuth = function () {
    var dsn = this._dsnObject;
    var auth = {
      // We send only the minimum set of required information. See
      // https://github.com/getsentry/sentry-javascript/issues/2572.
      sentry_key: dsn.user,
      sentry_version: SENTRY_API_VERSION
    };
    return urlEncode(auth);
  };
  /** Returns only the path component for the store endpoint. */
  API.prototype.getStoreEndpointPath = function () {
    var dsn = this._dsnObject;
    return (dsn.path ? "/" + dsn.path : '') + "/api/" + dsn.projectId + "/store/";
  };
  /**
   * Returns an object that can be used in request headers.
   * This is needed for node and the old /store endpoint in sentry
   */
  API.prototype.getRequestHeaders = function (clientName, clientVersion) {
    var dsn = this._dsnObject;
    var header = ["Sentry sentry_version=" + SENTRY_API_VERSION];
    header.push("sentry_client=" + clientName + "/" + clientVersion);
    header.push("sentry_key=" + dsn.user);
    if (dsn.pass) {
      header.push("sentry_secret=" + dsn.pass);
    }
    return {
      'Content-Type': 'application/json',
      'X-Sentry-Auth': header.join(', ')
    };
  };
  /** Returns the url to the report dialog endpoint. */
  API.prototype.getReportDialogEndpoint = function (dialogOptions) {
    if (dialogOptions === void 0) {
      dialogOptions = {};
    }
    var dsn = this._dsnObject;
    var endpoint = this.getBaseApiEndpoint() + "embed/error-page/";
    var encodedOptions = [];
    encodedOptions.push("dsn=" + dsn.toString());
    for (var key in dialogOptions) {
      if (key === 'user') {
        if (!dialogOptions.user) {
          continue;
        }
        if (dialogOptions.user.name) {
          encodedOptions.push("name=" + encodeURIComponent(dialogOptions.user.name));
        }
        if (dialogOptions.user.email) {
          encodedOptions.push("email=" + encodeURIComponent(dialogOptions.user.email));
        }
      } else {
        encodedOptions.push(encodeURIComponent(key) + "=" + encodeURIComponent(dialogOptions[key]));
      }
    }
    if (encodedOptions.length) {
      return endpoint + "?" + encodedOptions.join('&');
    }
    return endpoint;
  };
  return API;
}();
export { API };
