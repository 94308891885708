import { Injectable } from '@angular/core';

import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class NetworkIndicatorService {
  public indicatorActive$ = new BehaviorSubject(false);
  private activeCounter = 0;

  show() {
    if (!this.indicatorActive$.getValue()) {
      this.indicatorActive$.next(true);
    }

    this.activeCounter = Math.max(this.activeCounter, 0) + 1;
  }

  hide() {
    this.activeCounter -= 1;

    if (this.activeCounter <= 0) {
      this.indicatorActive$.next(false);
      this.activeCounter = 0;
    }
  }
}
