import { timestampWithMs } from '@sentry/utils';
/** Creates a SentryRequest from an event. */
export function eventToSentryRequest(event, api) {
  var useEnvelope = event.type === 'transaction';
  var req = {
    body: JSON.stringify(event),
    url: useEnvelope ? api.getEnvelopeEndpointWithUrlEncodedAuth() : api.getStoreEndpointWithUrlEncodedAuth()
  };
  // https://develop.sentry.dev/sdk/envelopes/
  // Since we don't need to manipulate envelopes nor store them, there is no
  // exported concept of an Envelope with operations including serialization and
  // deserialization. Instead, we only implement a minimal subset of the spec to
  // serialize events inline here.
  if (useEnvelope) {
    var envelopeHeaders = JSON.stringify({
      event_id: event.event_id,
      // We need to add * 1000 since we divide it by 1000 by default but JS works with ms precision
      // The reason we use timestampWithMs here is that all clocks across the SDK use the same clock
      sent_at: new Date(timestampWithMs() * 1000).toISOString()
    });
    var itemHeaders = JSON.stringify({
      type: event.type
    });
    // The trailing newline is optional. We intentionally don't send it to avoid
    // sending unnecessary bytes.
    //
    // const envelope = `${envelopeHeaders}\n${itemHeaders}\n${req.body}\n`;
    var envelope = envelopeHeaders + "\n" + itemHeaders + "\n" + req.body;
    req.body = envelope;
  }
  return req;
}
