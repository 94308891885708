import { NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule, Routes } from '@angular/router';

import { ActiveUserGuard } from 'src/app/shared/guards/active-user.guard';
import { AuthGuard } from 'src/app/shared/guards/auth.guard';
import { ShellComponent } from 'src/app/shell/shell.component';

const routes: Routes = [
  {
    path: '',
    redirectTo: '/report/quick',
    pathMatch: 'full',
  },
  {
    path: 'auth',
    loadChildren: () => import('src/app/modules/auth/auth.module').then((m) => m.AuthModule),
  },
  {
    path: '',
    component: ShellComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: 'dashboard',
        redirectTo: '/report/quick',
        pathMatch: 'full',
      },
      {
        path: 'setting',
        loadChildren: () => import('src/app/modules/setting/setting.module').then((m) => m.SettingModule),
      },
      {
        path: 'offer',
        canActivate: [ActiveUserGuard],
        loadChildren: () => import('src/app/modules/offers/offers.module').then((m) => m.OffersModule),
      },
      {
        path: 'ad-space',
        canActivate: [ActiveUserGuard],
        loadChildren: () => import('src/app/modules/ad-space/ad-space.module').then((m) => m.AdSpaceModule),
      },
      {
        path: 'account-administration',
        loadChildren: () => import('src/app/modules/account-management/account-management.module').then((m) => m.AccountManagementModule),
      },
      {
        path: 'finance',
        canActivate: [ActiveUserGuard],
        loadChildren: () => import('src/app/modules/finance/finance.module').then((m) => m.FinanceModule),
      },
      {
        path: 'report',
        canActivate: [ActiveUserGuard],
        loadChildren: () => import('src/app/modules/report/report.module').then((m) => m.ReportModule),
      },
      {
        path: 'postbacks',
        canActivate: [ActiveUserGuard],
        loadChildren: () => import('src/app/modules/postback/postback.module').then((m) => m.PostbackModule),
      },
      {
        path: '',
        loadChildren: () => import('src/app/modules/misc/misc.module').then((m) => m.MiscModule),
      },
      {
        path: 'bulk',
        loadChildren: () => import('src/app/modules/bulk/bulk.module').then((m) => m.BulkModule),
      },
      {
        path: '',
        redirectTo: 'dashboard',
        pathMatch: 'full',
      },
    ],
  },
  {
    path: 'legal',
    loadChildren: () => import('src/app/modules/legal/legal.module').then((m) => m.LegalModule),
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes), BrowserAnimationsModule],
  exports: [RouterModule],
})
export class AppRoutingModule {}
