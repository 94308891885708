import * as tslib_1 from "tslib";
import { API, BaseClient } from '@sentry/core';
import { getGlobalObject, logger } from '@sentry/utils';
import { BrowserBackend } from './backend';
import { Breadcrumbs } from './integrations';
import { SDK_NAME, SDK_VERSION } from './version';
/**
 * The Sentry Browser SDK Client.
 *
 * @see BrowserOptions for documentation on configuration options.
 * @see SentryClient for usage documentation.
 */
var BrowserClient = /** @class */function (_super) {
  tslib_1.__extends(BrowserClient, _super);
  /**
   * Creates a new Browser SDK instance.
   *
   * @param options Configuration options for this SDK.
   */
  function BrowserClient(options) {
    if (options === void 0) {
      options = {};
    }
    return _super.call(this, BrowserBackend, options) || this;
  }
  /**
   * @inheritDoc
   */
  BrowserClient.prototype._prepareEvent = function (event, scope, hint) {
    event.platform = event.platform || 'javascript';
    event.sdk = tslib_1.__assign({}, event.sdk, {
      name: SDK_NAME,
      packages: tslib_1.__spread(event.sdk && event.sdk.packages || [], [{
        name: 'npm:@sentry/browser',
        version: SDK_VERSION
      }]),
      version: SDK_VERSION
    });
    return _super.prototype._prepareEvent.call(this, event, scope, hint);
  };
  /**
   * @inheritDoc
   */
  BrowserClient.prototype._sendEvent = function (event) {
    var integration = this.getIntegration(Breadcrumbs);
    if (integration) {
      integration.addSentryBreadcrumb(event);
    }
    _super.prototype._sendEvent.call(this, event);
  };
  /**
   * Show a report dialog to the user to send feedback to a specific event.
   *
   * @param options Set individual options for the dialog
   */
  BrowserClient.prototype.showReportDialog = function (options) {
    if (options === void 0) {
      options = {};
    }
    // doesn't work without a document (React Native)
    var document = getGlobalObject().document;
    if (!document) {
      return;
    }
    if (!this._isEnabled()) {
      logger.error('Trying to call showReportDialog with Sentry Client is disabled');
      return;
    }
    var dsn = options.dsn || this.getDsn();
    if (!options.eventId) {
      logger.error('Missing `eventId` option in showReportDialog call');
      return;
    }
    if (!dsn) {
      logger.error('Missing `Dsn` option in showReportDialog call');
      return;
    }
    var script = document.createElement('script');
    script.async = true;
    script.src = new API(dsn).getReportDialogEndpoint(options);
    if (options.onLoad) {
      script.onload = options.onLoad;
    }
    (document.head || document.body).appendChild(script);
  };
  return BrowserClient;
}(BaseClient);
export { BrowserClient };
