import { ErrorHandler, Injectable } from '@angular/core';
import * as Sentry from '@sentry/browser';

@Injectable()
export class SentryErrorHandler implements ErrorHandler {
  handleError(error) {
    const exception = error.originalError || error;
    console.error(exception);

    Sentry.captureException(exception);
  }
}
