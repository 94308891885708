import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { JwtService } from 'src/app/shared/services/jwt.service';

import { Observable } from 'rxjs';
@Injectable()
export class TokenInterceptor implements HttpInterceptor {
  constructor(public jwtService: JwtService) { }
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (this.jwtService.getToken()) {
      request = request.clone({
        setHeaders: {
          Authorization: `JWT ${this.jwtService.getToken()}`,
        },
      });
    }
    return next.handle(request);
  }
}
